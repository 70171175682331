export interface StartupType {
    id: string;
    name: string;
    annual_revenue: number | null;
    number_of_customers: number | null;
    gross_profit: number | null;
    current_funding: number | null;
    last_market_cap: number | null;
    trying_to_raise: number | null;
    product_synthesis: string | null;
    burn_rate: number | null;
    expected_revenue: number | null;
    market_size: number | null;
    year_founded: number | null;
    roadmap: string | null;
    region: string | null;
    industry: string | null;
    team_members: number | null;
    description: string | null;
    headcount: number | null;
    relevant_links?: string[];
  }

export const startups = [
    {
        id: "healthtech_ai",
        name: "HealthTech AI",
        annual_revenue: 0,
        number_of_customers: 0,
        gross_profit: 0,
        current_funding: 2000000,
        last_market_cap: null,
        trying_to_raise: 5000000,
        product_synthesis: "AI-powered diagnostic tool for rare diseases",
        burn_rate: 150000,
        expected_revenue: 1000000,
        market_size: 50000000000,
        year_founded: 2022,
        roadmap: "Launch beta in Q3, full release in Q1 next year",
        region: "North America",
        industry: "Healthcare",
        team_members: 12,
        description: "Using machine learning to revolutionize rare disease diagnosis",
        headcount: 15,
        relevant_links: ["https://healthtechai.com", "https://twitter.com/healthtechai"]
    },
    {
        id: "finflow",
        name: "FinFlow",
        annual_revenue: 5000000,
        number_of_customers: 10000,
        gross_profit: 3500000,
        current_funding: 15000000,
        last_market_cap: 100000000,
        trying_to_raise: 30000000,
        product_synthesis: "B2B SaaS for streamlined financial operations",
        burn_rate: 400000,
        expected_revenue: 10000000,
        market_size: 200000000000,
        year_founded: 2019,
        roadmap: "Expand to European market in Q4",
        region: "Europe",
        industry: "Fintech",
        team_members: 50,
        description: "Simplifying financial workflows for medium to large enterprises",
        headcount: 65,
        relevant_links: ["https://finflow.io", "https://linkedin.com/company/finflow"]
    },
    {
        id: "ecopackage",
        name: "EcoPackage",
        annual_revenue: 1000000,
        number_of_customers: 50,
        gross_profit: 300000,
        current_funding: 5000000,
        last_market_cap: null,
        trying_to_raise: 10000000,
        product_synthesis: "Biodegradable packaging solutions for e-commerce",
        burn_rate: 200000,
        expected_revenue: 3000000,
        market_size: 80000000000,
        year_founded: 2020,
        roadmap: "Scale production capacity by 300% in next 18 months",
        region: "Asia",
        industry: "Sustainability",
        team_members: 25,
        description: "Revolutionizing e-commerce packaging with eco-friendly alternatives",
        headcount: 30,
        relevant_links: ["https://ecopackage.com", "https://instagram.com/ecopackage"]
    },
    {
        id: "learnloop",
        name: "LearnLoop",
        annual_revenue: 2000000,
        number_of_customers: 5000,
        gross_profit: 1500000,
        current_funding: 8000000,
        last_market_cap: 40000000,
        trying_to_raise: null,
        product_synthesis: "AI-driven personalized learning platform",
        burn_rate: 250000,
        expected_revenue: 5000000,
        market_size: 350000000000,
        year_founded: 2018,
        roadmap: "Launch enterprise solution in Q2 next year",
        region: "North America",
        industry: "EdTech",
        team_members: 40,
        description: "Transforming corporate training with adaptive learning technology",
        headcount: 45,
        relevant_links: ["https://learnloop.ai", "https://twitter.com/learnloopai"]
    },
    {
        id: "urbanfarm",
        name: "UrbanFarm",
        annual_revenue: 500000,
        number_of_customers: 20,
        gross_profit: 100000,
        current_funding: 3000000,
        last_market_cap: null,
        trying_to_raise: 7000000,
        product_synthesis: "Vertical farming solutions for urban areas",
        burn_rate: 180000,
        expected_revenue: 2000000,
        market_size: 30000000000,
        year_founded: 2021,
        roadmap: "Open 5 new urban farm locations in next 12 months",
        region: "Europe",
        industry: "AgTech",
        team_members: 15,
        description: "Bringing sustainable, local food production to cities",
        headcount: 20,
        relevant_links: ["https://urbanfarm.tech", "https://linkedin.com/company/urbanfarm"]
    },
    {
        id: "cybershield",
        name: "CyberShield",
        annual_revenue: 10000000,
        number_of_customers: 200,
        gross_profit: 7000000,
        current_funding: 50000000,
        last_market_cap: 300000000,
        trying_to_raise: 100000000,
        product_synthesis: "AI-powered cybersecurity platform for enterprises",
        burn_rate: 1000000,
        expected_revenue: 20000000,
        market_size: 500000000000,
        year_founded: 2017,
        roadmap: "Launch quantum-resistant encryption module in Q1 next year",
        region: "North America",
        industry: "Cybersecurity",
        team_members: 100,
        description: "Protecting global enterprises from next-gen cyber threats",
        headcount: 120,
        relevant_links: ["https://cybershield.io", "https://twitter.com/cybershieldio"]
    },
    {
        id: "mindfulme",
        name: "MindfulMe",
        annual_revenue: 1500000,
        number_of_customers: 100000,
        gross_profit: 1000000,
        current_funding: 4000000,
        last_market_cap: null,
        trying_to_raise: 10000000,
        product_synthesis: "AI-powered mental health app",
        burn_rate: 200000,
        expected_revenue: 5000000,
        market_size: 100000000000,
        year_founded: 2020,
        roadmap: "Integrate with wearables for biofeedback in Q3",
        region: "Global",
        industry: "HealthTech",
        team_members: 30,
        description: "Democratizing mental health support with AI and mindfulness",
        headcount: 35,
        relevant_links: ["https://mindfulme.app", "https://instagram.com/mindfulmeapp"]
    },
    {
        id: "quickcontract",
        name: "QuickContract",
        annual_revenue: 3000000,
        number_of_customers: 5000,
        gross_profit: 2500000,
        current_funding: 10000000,
        last_market_cap: 70000000,
        trying_to_raise: null,
        product_synthesis: "AI-powered contract management and analysis platform",
        burn_rate: 300000,
        expected_revenue: 8000000,
        market_size: 150000000000,
        year_founded: 2019,
        roadmap: "Release API for third-party integrations in Q4",
        region: "Europe",
        industry: "LegalTech",
        team_members: 45,
        description: "Streamlining contract workflows for legal departments worldwide",
        headcount: 50,
        relevant_links: ["https://quickcontract.legal", "https://linkedin.com/company/quickcontract"]
    },
    {
        id: "greenride",
        name: "GreenRide",
        annual_revenue: 500000,
        number_of_customers: 50000,
        gross_profit: 100000,
        current_funding: 7000000,
        last_market_cap: null,
        trying_to_raise: 20000000,
        product_synthesis: "Electric scooter sharing platform",
        burn_rate: 400000,
        expected_revenue: 3000000,
        market_size: 300000000000,
        year_founded: 2021,
        roadmap: "Expand to 10 new cities in next 6 months",
        region: "Asia",
        industry: "Transportation",
        team_members: 35,
        description: "Revolutionizing urban mobility with sustainable, shared transportation",
        headcount: 40,
        relevant_links: ["https://greenride.co", "https://twitter.com/greenrideco"]
    },
    {
        id: "datasculpt",
        name: "DataSculpt",
        annual_revenue: 8000000,
        number_of_customers: 100,
        gross_profit: 6000000,
        current_funding: 30000000,
        last_market_cap: 200000000,
        trying_to_raise: 50000000,
        product_synthesis: "No-code data analytics platform for enterprises",
        burn_rate: 800000,
        expected_revenue: 15000000,
        market_size: 400000000000,
        year_founded: 2018,
        roadmap: "Launch industry-specific templates in Q2 next year",
        region: "Global",
        industry: "Data Analytics",
        team_members: 80,
        description: "Empowering businesses to make data-driven decisions without coding",
        headcount: 90,
        relevant_links: ["https://datasculpt.io", "https://linkedin.com/company/datasculpt"]
    }
];