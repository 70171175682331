import React from 'react';

import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import { fetchGoogleCredentials } from '../apis/fetchGoogle.tsx';
import { useAuth, TokenType } from '../contexts/AuthProvider.tsx';
import Dashboard from '../components/Dashboard.tsx';


const Landing = () => {   
    const navigate = useNavigate();  
    const { token, setToken, signedIn, setSignedIn } = useAuth();
    // when the user clicks on `Sign In With Google`, 
    // fetchCredentials makes a client side call to url/token/verify-token
    const fetchCredentials = async (credentialResponse) => {
        const credential = credentialResponse.credential;
        const response = await fetchGoogleCredentials(credential);
        // Assuming response.decoded_token contains all the necessary fields
        const decoded_token = response.decoded_token;
        const tokenData: TokenType = {
            email: decoded_token.email,
            name: decoded_token.name,
            family_name: decoded_token.family_name,
            given_name: decoded_token.given_name,
            iss: decoded_token.iss,
            exp: decoded_token.exp,
            picture: decoded_token.picture,
            jti: decoded_token.jti,
            hd: decoded_token.hd,
        };
        setToken(tokenData);
        localStorage.setItem("token", JSON.stringify(tokenData));
        setSignedIn(true);
    }
    
    return (
    <div className="bg-neutral-900 w-full min-h-screen flex flex-col gap-2">
    <div className="flex">
    <div className="flex flex-col my-5 items-start w-11/12 mx-auto sm:mx-32">
        <h1 className="text-white font-DMSans font-extrabold text-5xl sm:text-7xl tracking-tight flex flex-col gap-3 items-start">
            <span className="">Extract the essentials from pitch decks </span>
            <span className="whitespace-nowrap ">
                <span className="mr-3 sm:mr-4 md:mr-5">in</span>
                <span className="relative whitespace-nowrap">
                    <span className="absolute bg-neutral-50 -left-2 -top-1 -bottom-1 -right-2 md:-left-3 md:-top-0 md:-bottom-0 md:-right-3 -rotate-1"></span>
                    <span className="relative text-black">seconds</span>
                </span>
            </span>
        </h1>
        {/* <h2 className="mt-4 text-slate-200 font-extrabold text-xl">
        straight from your Gmail inbox
        </h2> */}
{/* 
        <div className="mt-4 text-slate-400 text-lg text-left">
            Extract financials, market size, team credentials. 
            Spend less time reading and focus on making better decisions.
        </div> */}

        {/* 
        Google Login Button.
        docs: https://github.com/MomenSherif/react-oauth
        the authorized origins and redirect URI are set in the Google Cloud console:
        https://console.cloud.google.com/apis/credentials?project=ethereal-accord-431310-h8
        under OAuth 2.0 Client IDs.
        */}
        <div className="flex mx-auto mt-8">
        {/* copied as SVG from Excalidraw */}
            {/* <svg className="size-10 -translate-y-4" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.862707283497 126.75412562370303" width="64" height="64">
                <path d="M0.06 1.83 C5.77 11.24, 15.1 37.48, 32.34 54.58 C49.59 71.68, 92.33 95.99, 103.52 104.42 M-3.34 0.35 C3.41 10.24, 19.94 39.38, 37.63 57.17 C55.32 74.97, 92.56 98.67, 102.77 107.1" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                <path d="M80.06 98.67 C82.95 103.15, 89.35 101.88, 102.75 106.29 M78.41 99.19 C86.63 102.84, 95.39 104.83, 103.11 107.05" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                <path d="M90.16 84.86 C90.6 92.57, 94.65 94.52, 102.75 106.29 M88.51 85.39 C93.23 94, 98.41 100.87, 103.11 107.05" stroke="#fcbf23" stroke-width="4" fill="none"></path>
            </svg> */}

            {/* <GoogleLogin
                onSuccess={(credentialResponse) => {
                    fetchCredentials(credentialResponse)
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                text="continue_with"
                shape="pill"
                size="large"
            /> */}

            <button onClick={() => navigate("/signup")} type="submit" className="py-2 px-4 rounded-lg hover:scale-105 transition ease-in-out transition-duration-150 mx-auto text-center bg-yellow-400 text-black font-extrabold text-xl">
                Read my pitch decks
            </button>
        </div>

        <div className="mt-10 flex items-end gap-2">
            {/* <div className="flex flex-col">
                <span className="text-xl font-extrabold text-white">Hey {token?.given_name}, you caught us a bit early</span>
                <span className="text-sm font-bold text-neutral-400">(it's ok we won't hold it against you)</span>
                <span className="text-sm font-bold text-white mt-3">To give you an idea of what we're working on, this is what PDFs sent to your inbox would be parsed into</span>
            </div> */}
            <span className="text-xl text-slate-200 w-64 sm:w-80">
                We automatically detect pitch decks sent to your Gmail inbox and transform them into structured data
            </span>
            <svg className="size-16 translate-y-12 mt-1" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.06113603136941 95.60964287493192">
                <path d="M0.06 1.83 C7.33 6.23, 29.92 12.63, 41.7 24.54 C53.47 36.45, 66.56 65.03, 70.72 73.27 M-3.34 0.35 C4.97 5.23, 34.77 14.53, 46.99 27.13 C59.2 39.73, 66.78 67.71, 69.97 75.96" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                <path d="M55.54 55.06 C56.41 62.45, 60.89 64.09, 69.94 75.15 M53.89 55.58 C59.25 63.7, 65.08 70.11, 70.31 75.9" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                <path d="M71.71 49.49 C68.77 58.19, 69.48 61.12, 69.94 75.15 M70.06 50.02 C69.72 60.22, 69.82 68.59, 70.31 75.9" stroke="#fcbf23" stroke-width="4" fill="none"></path>
            </svg>
        </div>
    </div>
    </div>

    <Dashboard/>

    {/* F.A.Q. REGION */}
    <div className="flex" id="#faq">
        <div className="flex flex-col gap-2 h-auto my-5 sm:my-1 sm:justify-center items-start w-11/12 mx-auto sm:mx-32">
            <h1 className="text-white font-DMSans font-extrabold text-5xl sm:text-6xl tracking-tight flex flex-col gap-3 items-start">
                F.A.Q.
            </h1>
            <div className="flex flex-col">
                <span className="text-white font-bold text-xl">
                    What do you do with my emails?
                </span>
                <span className="text-white">
                    When you receive an email, Google sends us a notification. We then check whether
                    it contains a pitch deck PDF or not.
                    If it does, we pass it through our extraction engine and structure all the info
                    into a table, that you can access.
                    If not, we simply ignore it.
                </span>
            </div>
            <div className="flex flex-col">
                <span className="text-white font-bold text-xl">
                    Do you store my emails?
                </span>
                <span className="text-white">
                    <span className="font-bold">No</span>, that would be both a privacy risk and
                    prohibitively expensive.
                    We only store pitch deck PDFs sent to your email and the info we extract from them.
                </span>
            </div>
            <div className="flex flex-col">
                <span className="text-white font-bold text-xl">
                    Can you send emails on my behalf?
                </span>
                <span className="text-white">
                    <span className="font-bold">No</span>, we only have "read" access to your emails.
                </span>
            </div>
        </div>
    </div>

    {/* PRICING REGION */}
    <div className="flex">
        <div className="flex flex-col h-auto my-5 sm:my-1 sm:justify-center items-start w-11/12 mx-auto sm:mx-32">
            <h1 id="#pricing" className="my-2 text-white font-DMSans font-extrabold text-5xl sm:text-6xl tracking-tight flex flex-col gap-3 items-start">
                Pricing
            </h1>
            <span className="font-bold text-white">
                Only pay for what you use.
            </span>
            <span className="text-white">Buy credits as needed, set a monthly limit, no subscription.</span>
            {/* <div className='flex flex-col w-full items-center'>
                <PriceCard title="Classic" price={29.99} features={[
                    '10 mails extraction per day.',
                    'Insights',
                    'Ranking'
                ]} />
                <PriceCard title="Premium" price={59.99} features={[
                    '50 mails extraction per day.',
                    'Insights',
                    'Ranking',
                ]} />
            </div> */}

        </div>
    </div>

    <div className="w-11/12 sm:mx-32 mx-auto min-h-24">
        <div className="text-neutral-200">
            A question? Send us an email at hello@get-haystack.com
        </div>

        <div className="mt-5 text-white font-bold">
            allright... get outta here...
        </div>
    </div>
    </div>
    )
}

export default Landing;