import React, { useState } from 'react';

import { useAuth } from '../contexts/AuthProvider.tsx';
import Dashboard from "../components/Dashboard.tsx";
import { signUpRequest } from '../apis/signUpRequest.tsx';

const SignUp = () => {
    const { signedIn, token } = useAuth();
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const submit = (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        if (!emailRegex.test(email)) {
            setValidEmail(false);
            return;
        }
        setSubmitted(true);
        signUpRequest(email);
    }

    let nameString = "";
    if (token?.given_name) {
        nameString = ` ${token.given_name}`;
    }
    return (
        <div>
            <div className="w-11/12 sm:w-1/2 mx-auto">
                <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                    <span className="text-2xl font-extrabold text-neutral-200">Hey{nameString}...</span>
                    <span className="text-2xl font-extrabold text-neutral-200">you caught us a bit early 👉👈</span>
                    <span className="text-sm font-semibold text-neutral-400">(it's ok we won't hold it against you)</span>
                    </div>
                    <div className="min-h-20 flex flex-col justify-center">
                    { !submitted &&
                    <form className="flex flex-col gap-2">
                    <span className="text-sm font-semibold text-neutral-200">
                        Leave us your email and we'll reach out when the app is ready
                    </span>
                    { !validEmail && <span className="text-xs text-red-300">mmh... doesn't look like an email address</span> }
                    <input 
                        value={email}
                        onKeyDown={(e) => { if (e.key === 'Enter') submit(e);}}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="tim@apple.com"
                        required />
                    <button onClick={submit} type="submit" className="relative mx-auto text-center">
                        <span className="absolute bg-yellow-400 -left-5 -top-1 -bottom-1 -right-5 md:-left-3 md:-top-0 md:-bottom-0 md:-right-3 -rotate-1">
                        </span>
                        <span className="relative text-black font-extrabold text-xl">
                            Submit!
                        </span>
                    </button>
                    </form>
                    }

                    { submitted && 
                    <div className="text-2xl font-extrabold text-neutral-200 text-center">Thanks!</div>
                    }
                    </div>

                    <span className="text-sm text-white mt-3 w-72">
                        In the meantime, 
                        this is what PDFs sent to your inbox would be parsed into
                        (try clicking on the rows to see more info)
                    </span>
                </div>
                <svg className="size-10 mx-auto mt-1" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.06113603136941 95.60964287493192">
                    <path d="M0.06 1.83 C7.33 6.23, 29.92 12.63, 41.7 24.54 C53.47 36.45, 66.56 65.03, 70.72 73.27 M-3.34 0.35 C4.97 5.23, 34.77 14.53, 46.99 27.13 C59.2 39.73, 66.78 67.71, 69.97 75.96" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                    <path d="M55.54 55.06 C56.41 62.45, 60.89 64.09, 69.94 75.15 M53.89 55.58 C59.25 63.7, 65.08 70.11, 70.31 75.9" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                    <path d="M71.71 49.49 C68.77 58.19, 69.48 61.12, 69.94 75.15 M70.06 50.02 C69.72 60.22, 69.82 68.59, 70.31 75.9" stroke="#fcbf23" stroke-width="4" fill="none"></path>
                </svg>
            </div>
            <Dashboard />
        </div>
    );
}

export default SignUp;