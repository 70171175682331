import axios from 'axios';

export const signUpRequest = async (email: string) => {
    let BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL_DEV}/users/signup`
    if (process.env.NODE_ENV == "production") {
        BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL_PROD}/users/signup`
    }

    // make a request to the backedn to verify the encrypted token.
    try {
        const response = await axios.post(
            BACKEND_URL,
            { email }
        );

        return;
    } catch(error) {
        throw error;
    }
}
